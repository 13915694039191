import BaseService, { type ResponseWrapperType } from '~~/home/services/BaseService'
import type {
	ConsultRequestType,
	EmailVerifyRequestType,
	LoginRequestType,
	LoginResponseType,
	MessageEventType,
	MfaRequestType,
	MfaResponseType,
	PassAuth,
	PassCallbackRequestType,
	PassCallbackResponseType,
	PassInfoType,
	SendEmailCodeRequestType,
	SendEmailCodeResponseType,
	SetPasswordRequestType,
	SignupRequestType,
	TermListRequestType,
	TermListResponseType,
	TermsAgreeRequestType,
	TermsDetailRequestType,
	TermsDetailResponseType,
	TermsDropdownResponseType,
	UnAuthSessionInfoResponseType,
	VerifyMfaRequestType,
	WithdrawalListResponseType,
	WithdrawalRequestType
} from '~~/home/types/auth/auth'
import type { YesOrNoType } from '~~/home/types/BaseTypes'

const apiPrefix = ''
const passDevPrefix = '/unauth/pass/popup/dev'
const passProductionPrefix = '/unauth/pass/popup'
const passPrefix = process.env.NODE_ENV === 'development' ? passDevPrefix : passProductionPrefix
let authWindow: Window | null

class AuthService extends BaseService {
	/*
		signup : 회원가입 
		pwdReset : 비밀번호 재설정 
		userModify : 회원 정보 변경 
		userInfoUdt : 마이_회원_전화번호변경 
		withdrawal : 회원 탈퇴 
		subsUdt : 청약(요금제) 수정 
		subsWithdrawal : 청약(요금제) 해지 
		subsApply : 신규 청약(요금제) 요청
	*/
	async openPassPopup(action: string = 'signup'): Promise<ResponseWrapperType<PassAuth | null>> {
		if (!action) return Promise.reject({ code: 400, msg: 'invalid action', data: null })
		try {
			if (authWindow) {
				authWindow.close()
			}
			authWindow = window.open(
				'',
				'idaasPassPopup',
				'width=500, height=550, top=0, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
			)
			const { code, msg, data } = await this.post<any, ResponseWrapperType<string>>(`${apiPrefix}${passPrefix}/${action}`, {})
			return new Promise((resolve, reject) => {
				if (code === 200 && data) {
					const listener = (event: MessageEvent<MessageEventType>) => {
						window.removeEventListener('message', listener)
						if (event?.data?.message === 'PassPopupClosed') {
							resolve({ code: 200, msg: 'PassPopupClosed', data: event.data.result })
						} else {
							reject({ code: 500, msg: 'error', data: null })
						}
					}
					window.removeEventListener('message', listener)
					window.addEventListener('message', listener, false)

					if (authWindow) {
						authWindow.document.open()
						authWindow.document.write(data)
						authWindow.document.close()
					}
				} else {
					reject(false)
				}
			})
		} catch (error) {
			return { code: 500, msg: 'error', data: null }
		}
	}

	async openConsult(req: ConsultRequestType) {
		try {
			const { code, msg, data } = await this.post<any, any>(`${apiPrefix}/unauth/inquiry/consult`, req)
			return new Promise((resolve, reject) => {
				if (code === 200 && data) {
					const authWindow = window.open(
						'',
						'_blank',
						'width=500, height=550, top=0, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=yes'
					)
					if (authWindow) {
						authWindow.document.open()
						authWindow.document.write(data)
						authWindow.document.close()
					}
				} else {
					reject(false)
				}
			})
		} catch {
			return { code: 500, msg: 'error', data: null }
		}
	}

	async openPassPopupKcm() {
		const returnUrl = `${this.frontHost}${this.frontPath}/pass-cert-action.html`
		let url = `${this.host}${this.basePath}${apiPrefix}/unauth/signup/pass/popup?opener=${returnUrl}`
		if (this.isDev) url = `${this.host}${this.basePath}${apiPrefix}/unauth/signup/pass/popup/test?opener=${returnUrl}`
		// console.log("===============", url)
		return window.open(
			url,
			'passPopupChk',
			'width=500, height=550, top=0, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
		)
	}

	async callbackPassInfo(): Promise<ResponseWrapperType<PassCallbackResponseType>> {
		const resp = await this.post<null, PassCallbackResponseType>(`${apiPrefix}/unauth/signup/pass/info`, null)
		if (resp.code === 200 && resp.data) {
			resp.data.name = this.base64Decode(resp.data.name)
			resp.data.email = this.base64Decode(resp.data.email)
			resp.data.phoneNumber = this.base64Decode(resp.data.phoneNumber)
		}
		return resp
	}

	async callbackPassPopup(body: PassCallbackRequestType): Promise<ResponseWrapperType<PassCallbackResponseType>> {
		return await this.get<PassCallbackRequestType, PassCallbackResponseType>(`${apiPrefix}/unauth/pass/test/callback`, body)
	}

	/**
	 * 이메일 인증 코드 발송
	 * @param email
	 */
	async sendEmailCode(email: string): Promise<ResponseWrapperType<SendEmailCodeResponseType>> {
		const body: SendEmailCodeRequestType = {
			email: this.base64Encode(email)
		}
		return this.post<SendEmailCodeRequestType, SendEmailCodeResponseType>(`${apiPrefix}/unauth/signup/email/auth/code/request`, body)
	}

	/**
	 * 이메일 코드 인증
	 * @param authCode
	 */
	async verifyEmailCode(authCode: string): Promise<ResponseWrapperType<null>> {
		const body: EmailVerifyRequestType = {
			authCode
		}
		return this.post<EmailVerifyRequestType, null>(`${apiPrefix}/unauth/signup/email/auth/code/verify`, body)
	}

	async signup(body: SignupRequestType): Promise<ResponseWrapperType<null>> {
		return this.post<SignupRequestType, null>(`${apiPrefix}/unauth/signup`, body)
	}

	/**
	 * 인증 키 초기화
	 */
	async clearKey() {
		await this.get('/api/iam/admin/v1.0/login/clear_session', null)
	}

	async getTerms(body: TermListRequestType): Promise<ResponseWrapperType<TermListResponseType>> {
		return await this.post<TermListRequestType, TermListResponseType>(`${apiPrefix}/unauth/term/list`, body)
	}

	async getSignupTerms(): Promise<ResponseWrapperType<TermListResponseType>> {
		return await this.post<null, TermListResponseType>(`${apiPrefix}/unauth/signup/term/list`, null)
	}

	async getTermsDetail(body: TermsDetailRequestType): Promise<ResponseWrapperType<TermsDetailResponseType>> {
		return await this.post<TermsDetailRequestType, TermsDetailResponseType>(`${apiPrefix}/unauth/term/detail`, body)
	}

	async getTermsDropdown(body: TermsDetailRequestType): Promise<ResponseWrapperType<TermsDropdownResponseType>> {
		return await this.post<TermsDetailRequestType, TermsDropdownResponseType>(`${apiPrefix}/unauth/term/dropdown`, body)
	}

	async agreeTerms(body: TermsAgreeRequestType): Promise<ResponseWrapperType<null>> {
		return this.post<TermsAgreeRequestType, null>(`${apiPrefix}/unauth/user/term/agree`, body)
	}

	async logout(): Promise<ResponseWrapperType<null>> {
		return this.post<null, null>(`${apiPrefix}/user/logout`, null)
	}

	async setPassword(password: string): Promise<ResponseWrapperType<null>> {
		const body: SetPasswordRequestType = {
			password: this.base64Encode(password)
		}
		return this.post<SetPasswordRequestType, null>(`${apiPrefix}/unauth/signup/password`, body)
	}

	async setResetPassword(password: string): Promise<ResponseWrapperType<null>> {
		const body: SetPasswordRequestType = {
			password: this.base64Encode(password)
		}
		return this.post<SetPasswordRequestType, null>(`${apiPrefix}/unauth/password/reset`, body)
	}

	async getPassInfo(actionCode: string): Promise<ResponseWrapperType<PassInfoType>> {
		const resp = await this.post<{}, null>(`${apiPrefix}/unauth/pass/info/${actionCode}`, {})
		if (resp.code === 200 && resp.data) {
			resp.data.name = this.base64Decode(resp.data.name)
			resp.data.email = this.base64Decode(resp.data.email)
			resp.data.phoneNumber = this.base64Decode(resp.data.phoneNumber)
		}
		return resp
	}

	async checkLogin(): Promise<ResponseWrapperType<null>> {
		return this.post<null, null>(`${apiPrefix}/user/auth/check`, null)
	}

	async checkPassword(password: string): Promise<ResponseWrapperType<null>> {
		const body: SetPasswordRequestType = {
			password
		}
		return this.post<SetPasswordRequestType, null>(`${apiPrefix}/user/auth/password/check`, body)
	}

	async getWithdrawalList(): Promise<ResponseWrapperType<WithdrawalListResponseType>> {
		return this.post<null, WithdrawalListResponseType>(`${apiPrefix}/user/withdrawal/reason/list`, null)
	}

	async withdrawal(body: WithdrawalRequestType): Promise<ResponseWrapperType<null>> {
		return this.post<WithdrawalRequestType, null>(`${apiPrefix}/user/withdrawal`, body)
	}

	async login(email: string, password: string, keepLogin: YesOrNoType): Promise<ResponseWrapperType<LoginResponseType>> {
		const body: LoginRequestType = {
			username: this.base64Encode(email),
			password: this.base64Encode(password),
			keepLoginYn: keepLogin
		}
		return this.post<LoginRequestType, null>(`${apiPrefix}/unauth/login`, body)
	}

	async sendMfa(mfaType: string): Promise<ResponseWrapperType<MfaResponseType>> {
		const body: MfaRequestType = {
			mfaType
		}
		return this.post<MfaRequestType, MfaResponseType>(`${apiPrefix}/unauth/login/mfa`, body)
	}

	async verifyMfa(body: VerifyMfaRequestType): Promise<ResponseWrapperType<null>> {
		return this.post<MfaRequestType, MfaResponseType>(`${apiPrefix}/unauth/login/mfa/verify`, body)
	}

	async getUnauthSession(): Promise<ResponseWrapperType<UnAuthSessionInfoResponseType>> {
		const resp = await this.post(`${apiPrefix}/unauth/user/session/info`, {})
		if (resp.code === 200 && resp.data) {
			resp.data.name = this.base64Decode(resp.data.name)
			resp.data.email = this.base64Decode(resp.data.email)
			resp.data.phoneNumber = this.base64Decode(resp.data.phoneNumber)
			resp.data.subEmail = this.base64Decode(resp.data.subEmail)
		}
		return resp
	}

	base64Encode(str: string) {
		if (!str) {
			return str
		}
		const encoder = new TextEncoder()
		const encodedBytes = encoder.encode(str)
		return btoa(String.fromCharCode(...encodedBytes))
	}

	base64Decode(base64Str: string) {
		if (!base64Str) {
			return base64Str
		}
		const decodedBytes = Uint8Array.from(atob(base64Str), (c) => c.charCodeAt(0))
		return new TextDecoder().decode(decodedBytes)
	}
}

export default AuthService
